import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Container = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 20vh;
  padding-top: 20vh;
`

const Content = styled.div`
  width: 80%;
  margin: 10vh auto 20vh auto;
`

const Title = styled.h2`
  color: white;
  font-family: "Raleway", sans-serif;
  font-size: 42px;
  margin-top: 0;
`

const Subtitle = styled.p`
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  width: 88%;

  @media screen and (max-width: 900px) {
    font-size: 18px;
    width: 100%;
  }
`

const SubtitleLink = styled.a`
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 900px) {
    margin-top: 12px;
    font-size: 18px;
  }
`

class Team extends Component {
  render() {
    return (
      <Container id="team">
        <Content>
          <Title>Team</Title>
          <Subtitle>
            Clink is being built by world class engineers and
            lawyers-turned-entrepreneurs in the blockchain industry - from
            various firms, decentralized applications, and layer 1 blockchain
            projects.
          </Subtitle>
          <SubtitleLink
            href="mailto:info@clink.social"
            target="_blank"
            rel="noopener noreferrer"
          >
            If you want to join us, please get in touch{" "}
            <span role="img" aria-label="waving emoji">
              👋
            </span>
          </SubtitleLink>
        </Content>
      </Container>
    )
  }
}

export default Team
