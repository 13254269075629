import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Container = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 90vh;
`

const Title = styled.h1`
  color: white;
  font-family: "Raleway", sans-serif;
  font-size: 42px;
  text-align: left;
  width: 80%;
  margin: auto auto 0 auto;

  @media screen and (max-width: 900px) {
    margin-bottom: 36px;
    font-size: 28px;
  }
`

const FullTextContainer = styled(Link)`
  background-color: white;
  border-radius: 12px;
  text-decoration: none;
  box-shadow: none;
  color: black;
  margin: auto auto 0 auto;
  width: 80%;

  h2 {
    color: black;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 36px;
    padding: 24px 36px;
  }

  p {
    font-family: "Roboto", sans-serif;

    :nth-child(2) {
      padding: 0 36px;
      font-size: 24px;
      font-weight: 300;
      width: 50%;
    }

    :nth-child(3) {
      text-align: right;
      font-size: 24px;
      font-weight: 300;
      padding: 24px 36px 24px 0;
      margin: 0;
    }
  }

  @media screen and (max-width: 900px) {
    h2 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      :nth-child(2) {
        font-size: 18px;
        width: 80%;
      }
      :nth-child(3) {
        font-size: 18px;
      }
    }
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 2% auto;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`

const MediumTextContainer = styled(Link)`
  background-color: white;
  border-radius: 12px;
  text-decoration: none;
  box-shadow: none;
  color: black;
  margin: 0 auto auto auto;
  width: 60%;
  min-height: 300px;

  h2 {
    color: black;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 36px;
    padding: 24px 36px;
  }

  p {
    font-family: "Roboto", sans-serif;

    :nth-child(2) {
      padding: 0 36px 36px 36px;
      font-size: 24px;
      font-weight: 300;
      width: 80%;
    }
  }

  @media screen and (max-width: 900px) {
    margin-top: 24px;
    width: 100%;

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      :nth-child(2) {
        font-size: 18px;
        width: 80%;
      }
      :nth-child(3) {
        font-size: 18px;
      }
    }
  }
`

const SmallTextContainer = styled(Link)`
  background-color: white;
  border-radius: 12px;
  text-decoration: none;
  box-shadow: none;
  color: black;
  margin: 0 42px auto auto;
  width: 40%;
  min-height: 300px;

  h2 {
    color: black;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 36px;
    padding: 24px 36px;
  }

  p {
    font-family: "Roboto", sans-serif;

    :nth-child(2) {
      padding: 0 36px 36px 36px;
      font-size: 24px;
      font-weight: 300;
      width: 80%;
    }
  }

  @media screen and (max-width: 900px) {
    margin-top: 24px;
    width: 100%;

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      :nth-child(2) {
        font-size: 18px;
        width: 80%;
      }
      :nth-child(3) {
        font-size: 18px;
      }
    }
  }
`

class FeaturedBlogPosts extends Component {
  render() {
    return (
      <Container>
        <Title>Featured Blog Posts</Title>
        <Row>
          <SmallTextContainer to="/introducing-clink/">
            <h2>Introducing Clink</h2>
            <p>
              A geosocial network and gaming platform designed to restore magic
              to the world of face-to-face social experiences.
            </p>
          </SmallTextContainer>
          <MediumTextContainer to="/introducing-clink/">
            <h2>What is the Metaverse?</h2>
            <p>
              The Metaverse has become a vague shorthand for any number of
              converging technologies: AR/VR, ubiquitous computing, blockchain,
              5G, etc. But what are the defining features of this idea?
            </p>
          </MediumTextContainer>
        </Row>
      </Container>
    )
  }
}

export default FeaturedBlogPosts
