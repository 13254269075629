import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Partners from "../components/partners"
import FeaturedPosts from "../components/featured-blog-posts"
import Team from "../components/team"

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout
      location={location}
      title={siteTitle}
      heroTitle="About Clink"
      heroSubtitle="The Social Layer of the Metaverse"
    >
      <SEO title="About" />
      <Partners />
      <FeaturedPosts
        fullHeader="Announcing Clink"
        fullBody="A new way to connect in the real world, with novel incentive mechanisms and a proof-of-community protocol."
        fullFalloff="Hear from our CEO"
      />
      <Team />
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
