import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import FlowLogo from "../../content/assets/flow-logo.png"
import HederaLogo from "../../content/assets/hedera-hashgraph-logo-stacked.png"

const Container = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 40vh;
  padding: 12% 10% 0 10%;

  @media screen and (max-width: 900px) { 
    padding: 12% 4%;
  }
`

const Title = styled.h2`
  color: white;
  font-family: "Raleway", sans-serif;
  font-size: 42px;
  font-weight: 300;
  text-align: center;
  margin: auto;
  width: 80%;

  @media screen and (max-width: 900px) { 
    font-size: 24px;
    margin-bottom: 48px;
  }
`

const PartnerLogos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  width: 80%;

  @media screen and (max-width: 900px) { 
    flex-direction: column;
    width: 100%;
  }
`

const Partner = styled(Link)`
  padding: 36px;
  text-decoration: none;

  img {
    padding-top: 12px;
    height: 200px;
    max-width: 400px;
    width: auto;
  }

  p {
    color: white;
    font-family: "Roboto", sans-serif;
    text-align: center;
  }

  @media screen and (max-width: 900px) { 
    padding: 24px; 

    img { 
      height: auto;
      width: 100%;
      margin: auto;
    }
  }
`

class Partners extends Component {
  render() {
    return (
      <Container id="partners">
        <Title>
          Part of accelerator programs for leading layer 1 blockchains, with a
          hybrid technical solution
        </Title>
        <PartnerLogos>
          <Partner to="https://www.onflow.org/flow-alpha" target="_blank" >
            <img src={FlowLogo} alt="Flow Logo"/>
            <p>Flow Alpha</p>
          </Partner>
          <Partner to="https://hedera.com/boost" target="_blank" >
            <img src={HederaLogo} alt="Hedera Hashgraph Logo" />
            <p>Hedera Boost</p>
          </Partner>
        </PartnerLogos>
      </Container>
    )
  }
}

export default Partners
